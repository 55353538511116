/*---------
MISC TWEAKS
---------*/

/* replace elephant friend with vinyl */
.drawer__inner__mastodon {background: url("") no-repeat bottom center / contain !important;}
.drawer__inner__mastodon > img {display: none;}

/* repeating musical notes in background */
.columns-area {background: url("") space 0 0;}

/* vignette on headers and dropdowns */
.column-header {box-shadow: inset 0 0 8px 8px rgba(0,0,0,0.3);}
.column-header__button {background: transparent !important;}
.column-header__collapsible-inner {box-shadow: inset 0 0 10em 10em rgba(0,0,0,0.3);}
